<template>
  <v-dialog :value="internalValue" width="1000px" persistent @input="$emit('input', false)">
    <v-card v-if="internalValue" max-height="800" class="dialog-content d-flex flex-column">
      <v-card-title>
        <span v-if="!isNew" class="section-title">Editando a loja - {{ lazyUnit.id_erp }}</span>
        <span v-else class="section-title">Criando uma loja</span>
        <v-spacer />
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-0 flex-grow-1 overflow-y-auto">
        <div>
          <v-row>
            <v-col cols="12">
              <span class="detail-title">
                Loja sincronizará para o e-commerce se o campo URL - Subdominío Backoffice varejsita estiver preenchido. Cadastro feito dentro de Publicação de
                app > Settings > Geral. <a :href="`/#/accounts/${$route.params.id}/app-config`">Clique aqui para ver a configuração</a>
              </span>
            </v-col>
          </v-row>
          <v-row class="pl-3">
            <v-col v-if="adminView && isNew" class="pa-0 pr-6" cols="4">
              <label class="detail-title">ID ERP*</label>
              <mf-text-input v-model="lazyUnit.id_erp" autofocus :errors-messages="errorMessages.id_erp" outlined />
            </v-col>
            <v-col class="pa-0 pr-6" cols="4">
              <label class="detail-title">ID ecommerce</label>
              <mf-text-input v-model="lazyUnit.ecommerce_id" :disabled="isFranchise" autofocus :errors-messages="errorMessages.required" outlined />
            </v-col>
            <v-col v-if="disableButton" class="pa-0 pr-6" cols="4">
              <label class="detail-title">Bandeira</label>
              <mf-select v-model="lazyUnit.retailer_flag_id" :items="flagsType" :item-text="'name'" :item-value="'_id'" outlined></mf-select>
            </v-col>
          </v-row>
          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="4">
              <label class="detail-title">Loja do varejista*</label>
              <v-autocomplete
                v-model="selectedClientUnit"
                :disabled="!isNew"
                :items="clientUnitsItems"
                item-disabled="disabled"
                item-text="displayName"
                return-object
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="4">
              <label class="detail-title">Razão Social*</label>
              <mf-text-input v-model="lazyUnit.corporate_name" :errors-messages="errorMessages.corporate_name" disabled :autofocus="!adminView" outlined />
            </v-col>

            <v-col class="pa-0 pr-6" cols="4">
              <label class="detail-title">Nome*</label>
              <mf-text-input v-model="lazyUnit.name" :disabled="isFranchise" :autofocus="!adminView" :errors-messages="errorMessages.name" outlined />
            </v-col>

            <v-col class="py-0" cols="4">
              <label class="detail-title">CNPJ*</label>
              <mf-text-input v-model="lazyUnit.cnpj" v-mask="'##.###.###/####-##'" disabled :errors-messages="errorMessages.cnpj" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Insc. Estadual</label>
              <mf-text-input v-model="lazyUnit.statual_insc" disabled outlined />
            </v-col>

            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Insc. Municipal</label>
              <mf-text-input v-model="lazyUnit.municipal_insc" disabled outlined />
            </v-col>

            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Slug*</label>
              <mf-text-input v-model="lazyUnit.slug" :errors-messages="errorMessages.slug" :disabled="isFranchise" outlined />
            </v-col>

            <v-col class="py-0" cols="3">
              <label class="detail-title">Contato*</label>
              <mf-text-input
                v-model="lazyUnit.contact"
                v-mask="['+55 (##) #####-####', '+55 (##) ####-####']"
                :errors-messages="errorMessages.contact"
                :disabled="isFranchise"
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">CEP*</label>
              <mf-text-input
                v-model="internalPostalCode"
                v-mask="'#####-###'"
                disabled
                :loading="loadingCep"
                :errors-messages="errorMessages.postal_code"
                outlined
              />
            </v-col>
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Estado*</label>
              <v-select
                v-model="address.federative_unit"
                disabled
                label="Estado"
                :items="estados"
                item-text="name"
                item-value="value"
                :error-messages="errorMessages.federative_unit"
                outlined
                single-line
              />
            </v-col>
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Cidade*</label>
              <v-select
                v-model="address.city_name"
                disabled
                label="Cidade"
                :items="cidadesFiltradas"
                item-text="Nome"
                item-value="Nome"
                :error-messages="errorMessages.city_name"
                outlined
                single-line
              />
              <!-- :disabled="address.federative_unit === ''" -->
            </v-col>
            <v-col class="pa-0 pr-3" cols="3">
              <label class="detail-title">Bairro*</label>
              <mf-text-input v-model="address.neighborhood" disabled :errors-messages="errorMessages.neighborhood" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="6">
              <label class="detail-title">Rua*</label>
              <mf-text-input v-model="address.street" disabled :errors-messages="errorMessages.street" outlined />
            </v-col>
            <v-col class="pa-0 pr-6" cols="2">
              <label class="detail-title">Número*</label>
              <mf-text-input v-model="address.number" disabled :errors-messages="errorMessages.number" outlined />
            </v-col>
            <v-col class="pa-0 pr-6" cols="2">
              <label class="detail-title">Latitude*</label>
              <mf-text-input v-model="latitude" disabled :errors-messages="errorMessages.latitude" outlined suffix="°" />
            </v-col>
            <v-col class="pa-0 pr-3" cols="2">
              <label class="detail-title">Longitude*</label>
              <mf-text-input v-model="longitude" disabled :errors-messages="errorMessages.longitude" outlined suffix="°" />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0" cols="12">
              <label class="detail-title">Complemento</label>
              <mf-text-input v-model="address.complement" disabled :errors-messages="errorMessages.complement" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0" cols="12">
              <label class="detail-title">Endereço completo*</label>
              <mf-text-input v-model="internalFullAddress" :disabled="isFranchise" :errors-messages="errorMessages.address" outlined />
            </v-col>
          </v-row>

          <v-row class="office-hours-title"> <span>Horários de funcionamento</span></v-row>
          <v-row class="office-hours-content">
            <v-col v-for="[officeHoursDay, officeHours] in officeHoursDays" :key="officeHoursDay">
              <v-row justify="center">
                <span :class="[officeHours && officeHours.length ? '' : 'closed-office-hours']">{{ officeHoursTranslation[officeHoursDay] }}</span>
              </v-row>
              <v-row justify="center" class="office-hours-content-chips">
                <v-chip v-for="({ start, finish }, index) in officeHours" :key="index" x-small class="office-hours-chip">
                  <span class="clickable" @click="toggleEditOfficeHour(officeHoursDay, index, { start, finish })">{{ start }} às {{ finish }}</span>
                  <v-icon x-small @click="officeHours.splice(index, 1)">clear</v-icon>
                </v-chip>
              </v-row>
              <v-row v-if="officeHours.length < 2" justify="center" class="pt-1 office-hours-content-new">
                <v-icon small @click="toggleEditOfficeHour(officeHoursDay, officeHours.length)">add_circle</v-icon>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pl-3">
            <v-col class="pl-0 py-0" cols="3">
              <label class="detail-title">Segmento</label>
              <v-select v-model="lazyUnit.segments" multiple :items="segments" item-text="text" item-value="text" disabled outlined />
            </v-col>
            <v-col class="py-0" cols="2">
              <label class="detail-title">Total PDV</label>
              <mf-number-input v-model="lazyUnit.total_pdv_amount" show-arrows :min="0" disabled outlined />
            </v-col>
            <v-col class="py-0" cols="3">
              <label class="detail-title">Total PDV contradado</label>
              <mf-number-input v-model="lazyUnit.total_pdv_contracted" show-arrows :min="0" disabled outlined />
            </v-col>
            <v-col class="pa-0" cols="2">
              <label class="detail-title">Loja Principal</label>
              <v-switch v-model="lazyUnit.store_main" class="mt-0" disabled :label="`Sim`" />
            </v-col>
            <v-col class="pa-0" cols="2">
              <label class="detail-title">Aplicativo</label>
              <v-checkbox v-model="lazyUnit.mobile" class="mt-0" :disabled="isFranchise" label="Disponível no app" />
            </v-col>
          </v-row>
          <v-row class="pl-3">
            <v-col class="pa-0" cols="3">
              <label class="detail-title">CBM</label>
              <mf-tooltip v-if="!productEnabledCBM" bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox class="mt-0" disabled label="CBM ativo" hide-details />
                </template>
                <span>Não é possível ligar a flag CBM para esta loja, pois a loja selecionada não possui esse produto.</span>
              </mf-tooltip>
              <v-checkbox v-else v-model="lazyUnit.cbm" class="mt-0" label="CBM ativo" hide-details />
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Mercashop</label>
              <mf-tooltip v-if="!productEnabledMercashop" bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox class="mt-0" disabled label="Mercashop ativo" hide-details />
                </template>
                <span>Não é possível ligar a flag Mercashop para esta loja, pois a loja selecionada não possui esse produto.</span>
              </mf-tooltip>
              <v-checkbox v-else v-model="lazyUnit.mercashop" class="mt-0" :disabled="isFranchise" label="Mercashop ativo" hide-details />
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Mercapromo</label>
              <mf-tooltip v-if="!productEnabledMercapromo" bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox class="mt-0" disabled label="Mercapromo ativo" hide-details />
                </template>
                <span>Não é possível ligar a flag Mercapromo para esta loja, pois a loja selecionada não possui esse produto.</span>
              </mf-tooltip>
              <v-checkbox v-else v-model="lazyUnit.mercapromo" class="mt-0" :disabled="isFranchise" label="Mercapromo ativo" hide-details />
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Promotrade</label>
              <mf-tooltip v-if="!productEnabledPromotrade" bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox class="mt-0" disabled label="Promotrade ativo" hide-details />
                </template>
                <span>Não é possível ligar a flag Promotrade para esta loja, pois a loja selecionada não possui esse produto.</span>
              </mf-tooltip>
              <v-checkbox v-else v-model="lazyUnit.promotrade" class="mt-0" :disabled="isFranchise" label="Promotrade ativo" hide-details />
            </v-col>
          </v-row>
          <v-row v-if="errorMessages.atLeastOneProductActive" class="pl-3">
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">{{ errorMessages.atLeastOneProductActive }}</div>
              </div>
            </div>
          </v-row>
          <v-row class="pl-3">
            <v-col cols="9" class="pa-0">
              <label class="card-title">Token E-commerce</label>
            </v-col>
          </v-row>
          <v-row class="pl-3" align="center">
            <v-col cols="12" class="pa-0">
              <div class="d-flex align-center">
                <mf-text-input v-model="token_ecommerce" :disabled="isFranchise" outlined class="flex-grow-1" hide-details />
                <mf-button v-if="!isFranchise" color="primary" label="Gerar Token" class="ml-2" @click="generateToken" />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!isNew">
            <v-col cols="6">
              <label class="detail-title">Ativo</label>
              <v-switch v-model="lazyUnit.active" :disabled="isFranchise" :label="`Sim`" />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <mf-action-buttons
          :primary-button="{
            text: 'Salvar',
            action: save,
            isVisible: true,
            isDisabled: isSaveDisabled,
            tooltip: isSaveDisabled,
            tooltipMessage: disabledSaveTooltip,
            isLoading: saving
          }"
          :extra-button="{
            text: 'Cancelar',
            action: cancel,
            isVisible: true,
            isDisabled: saving
          }"
        />
      </v-card-actions>
    </v-card>
    <dialog-time-range
      v-if="settingOfficeHours"
      v-model="settingOfficeHours"
      :initialStart="selectedOfficeHoursStart"
      :initialFinish="selectedOfficeHoursFinish"
      :title="settingOfficeHoursTitle"
      :save="setOfficeHours"
    />
  </v-dialog>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { getDataFromViaCep } from '@/helpers/cep'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { notOnlySpace, validCnpj } from '@/helpers/validators.js'
import { data as cidades } from '@/assets/data/cidades.json'
import { data as estados } from '@/assets/data/estados.json'
import { QUERY_FLAGS } from '@/modules/accounts/graphql'
import cnpj from '@/filters/cnpj'
import { retailSegments } from '@/mixin'

const DEFAULT_ADDRESS = {
  postal_code: '',
  federative_unit: '',
  city_name: '',
  neighborhood: '',
  street: '',
  number: ''
}

export default {
  name: 'EditUnit',

  components: {
    DialogTimeRange: () => import('@/components/base/DialogTimeRange.vue')
  },
  mixins: [retailSegments],
  props: {
    saveCallback: Function,
    value: Boolean,
    unit: Object,
    isNew: Boolean,
    adminView: {
      type: Boolean,
      default: false
    },
    dbName: {
      type: String,
      default: undefined
    },
    isFranchisee: {
      type: Boolean,
      default: false
    },
    isFranchise: {
      type: Boolean,
      default: false
    },
    clientUnits: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
    lazyValue: false,
    lazyUnit: {},
    token_ecommerce: '',
    saving: false,
    settingOfficeHours: false,
    selectedOfficeHoursDay: null,
    selectedOfficeHoursPosition: null,
    selectedOfficeHoursStart: null,
    selectedOfficeHoursFinish: null,
    latitude: '',
    longitude: '',
    localFullAddress: '',
    cidades: cidades,
    estados: estados,
    fullAddressTouched: false,
    postalCodeTouched: false,
    loadingCep: false,
    address: DEFAULT_ADDRESS,
    accountId: null,
    flagsType: [{ _id: null, name: 'Sem Bandeira' }],
    disableButton: false,
    selectedClientUnit: {},
    productEnabledCBM: false,
    productEnabledMercashop: false,
    productEnabledMercapromo: false,
    productEnabledPromotrade: false
  }),
  computed: {
    isSaveDisabled() {
      return isEmpty(this.selectedClientUnit) || this.isFranchise
    },
    disabledSaveTooltip() {
      if (this.isFranchise) {
        return 'Não é possível editar lojas em franquias.'
      } else if (isEmpty(this.selectedClientUnit)) {
        return 'Selecione uma loja do varejista para atrelar uma loja na conta.'
      }
      return ''
    },
    clientUnitsItems() {
      return this.clientUnits.map(item => ({
        ...item,
        disabled: item.status === 'PENDING_CONFIGURATION',
        displayName: `[${cnpj(item.cnpj)}] - ${item.name}${item.status === 'PENDING_CONFIGURATION' ? ' - Desconfigurada' : ''}`,
        cnpj: cnpj(item.cnpj)
      }))
    },
    internalPostalCode: {
      get() {
        return this.address.postal_code
      },
      set(value) {
        const newRaw = value?.replace(/[^\d]+/g, '')
        const oldRaw = this.address.postal_code.replace(/[^\d]+/g, '')
        if (newRaw !== oldRaw) {
          this.postalCodeTouched = true
        }
        this.address.postal_code = value
      }
    },
    internalFullAddress: {
      get() {
        if (this.lazyUnit.address) {
          return this.lazyUnit.address
        }
        return this.defaultFullAddress
      },
      set(value) {
        this.fullAddressTouched = true
        this.localFullAddress = value
      }
    },
    internalValue: {
      get() {
        return this.lazyValue
      },
      set(value) {
        this.lazyValue = value
        this.$emit('input', value)
      }
    },
    defaultFullAddress() {
      const fullAddressComponents = []
      if (this.address.street) {
        fullAddressComponents.push(this.address.street)
      }

      if (this.address.number || this.address.neighborhood) {
        fullAddressComponents.push([this.address.number, this.address.neighborhood].filter(el => Boolean(el)).join(' - '))
      }

      if (this.address.city_name || this.address.federative_unit) {
        fullAddressComponents.push([this.address.city_name, this.address.federative_unit].filter(el => Boolean(el)).join(' - '))
      }

      if (this.address.postal_code) {
        fullAddressComponents.push(this.address.postal_code)
      }

      return fullAddressComponents.join(', ')
    },
    cidadesFiltradas() {
      return this.cidades.filter(cidade => cidade.Uf === this.address.federative_unit)
    },
    settingOfficeHoursTitle() {
      return `Horário para ${this.officeHoursTranslation[this.selectedOfficeHoursDay]}`
    },
    officeHoursTranslation() {
      return {
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado',
        sunday: 'Domingo',
        holidays: 'Feriados'
      }
    },
    officeHoursDays() {
      return this.lazyUnit.office_hours ? Object.entries(this.lazyUnit.office_hours).filter(([day]) => day !== '__typename') : []
    },
    errorMessages() {
      const fields = ['id_erp', 'cnpj', 'name', 'contact', 'corporate_name', 'slug']
      const errors = fields.reduce((errors, field) => {
        if (this.$v.lazyUnit[field] && this.$v.lazyUnit[field].$error) {
          if (this.$v.lazyUnit[field].required === false) errors[field] = 'Campo obrigatório.'
          else if (this.$v.lazyUnit[field].notOnlySpace === false) errors[field] = 'Não é permitido salvar apenas espaços.'
        } else {
          errors[field] = ''
        }
        return errors
      }, {})
      if (this.$v.lazyUnit.contact.minLength === false || this.$v.lazyUnit.contact.maxLength === false) errors.contact = 'Número inválido.'
      if (!errors.cnpj && !this.$v.lazyUnit.cnpj.validCnpj) errors.cnpj = 'CNPJ inválido.'
      if (this.$v.internalFullAddress.$error) errors['address'] = 'Endereço completo inválido.'
      if (this.$v.address.postal_code.$error) errors['postal_code'] = 'CEP inválido.'
      if (this.$v.address.federative_unit.$error) errors['federative_unit'] = 'Estado inválido.'
      if (this.$v.address.city_name.$error) errors['city_name'] = 'Selecione uma cidade.'
      if (this.$v.address.neighborhood.$error) errors['neighborhood'] = 'Bairro inválido.'
      if (this.$v.address.street.$error) errors['street'] = 'Rua inválida.'
      if (this.$v.address.number.$error) errors['number'] = 'Número inválido.'
      if (this.$v.latitude.$error) errors['latitude'] = 'Latitude inválida.'
      if (this.$v.longitude.$error) errors['longitude'] = 'Longitude inválida.'
      if (this.$v.$error && this.$v.lazyUnit?.atLeastOneProductActive === false) errors['atLeastOneProductActive'] = 'Selecione pelo menos um produto.'
      return errors
    }
  },
  apollo: {
    flags: {
      query: QUERY_FLAGS,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update({ retailerFlags }) {
        this.flagsType = this.flagsType.concat(retailerFlags)
        this.disableButton = retailerFlags.length > 0
      }
    }
  },
  watch: {
    value(value) {
      this.lazyValue = value
      // Reset field
      if (value) {
        this.setupUnit(this.unit)
      }
    },
    unit(value) {
      this.setupUnit(value)
    },
    selectedClientUnit(value) {
      this.lazyUnit.corporate_name = value.corporate_name
      this.lazyUnit.name = value.name
      this.lazyUnit.cnpj = value.cnpj
      this.lazyUnit.statual_insc = value.statual_insc
      this.lazyUnit.municipal_insc = value.municipal_insc
      this.lazyUnit.segments = value.segments
      this.lazyUnit.total_pdv_amount = value.total_pdv_amount
      this.lazyUnit.total_pdv_contracted = value.total_pdv_contracted
      this.lazyUnit.client_unit_id = value._id
      this.address.postal_code = value.address_components?.postal_code
      this.address.federative_unit = value.address_components?.federative_unit
      this.address.federative_unit = value.address_components?.federative_unit
      this.address.city_name = value.address_components?.city_name
      this.address.neighborhood = value.address_components?.neighborhood
      this.address.street = value.address_components?.street
      this.address.number = value.address_components?.number
      this.address.complement = value.address_components?.complement
      this.latitude = String(value.location?.coordinates?.[0] ?? '').replace('.', ',')
      this.longitude = String(value.location?.coordinates?.[1] ?? '').replace('.', ',')

      this.productEnabledCBM = value.cbm
      this.productEnabledMercashop = value.mercashop
      this.productEnabledMercapromo = value.mercapromo
      this.productEnabledPromotrade = value.promotrade
    },
    async 'address.postal_code'(value) {
      const raw = value?.replace(/[^\d]+/g, '')
      if (raw?.length !== 8 || !this.postalCodeTouched) {
        return
      }
      try {
        this.loadingCep = true
        const cepData = await getDataFromViaCep(raw)
        this.address.federative_unit = cepData.uf || ''
        this.address.city_name = cepData.localidade || ''
        this.address.neighborhood = cepData.bairro || ''
        this.address.street = cepData.logradouro || ''
      } catch (e) {
        this.$snackbar({ message: 'Falha ao procurar o CEP' })
      }
      this.loadingCep = false
    }
  },
  mounted() {
    this.lazyValue = this.value
    this.setupUnit(this.unit)
  },
  methods: {
    setupUnit(unit) {
      this.postalCodeTouched = false
      this.lazyUnit = cloneDeep(unit)
      if (this.lazyUnit?.location?.coordinates) {
        this.latitude = String(this.lazyUnit.location.coordinates[0]).replace('.', ',')
        this.longitude = String(this.lazyUnit.location.coordinates[1]).replace('.', ',')
      }
      if (this.lazyUnit?.address_components) {
        this.address = this.lazyUnit.address_components
      } else {
        this.address = cloneDeep(DEFAULT_ADDRESS)
      }
      if (!this.lazyUnit?.office_hours) {
        this.lazyUnit.office_hours = {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
          holidays: []
        }
      }
      if (!this.lazyUnit?.ecommerce) {
        this.lazyUnit.ecommerce = { active: false }
      }

      if (!this.isNew && this.lazyUnit?.active === null) {
        this.lazyUnit.active = true
      }

      if (this.dbName) this.lazyUnit.id_erp = this.dbName

      if (this.isFranchisee) this.accountId = this.lazyUnit.account
      if (this.lazyUnit?.client_unit_id) {
        this.selectedClientUnit = this.clientUnitsItems.find(item => item._id === this.lazyUnit.client_unit_id)
      } else {
        this.selectedClientUnit = {}
      }

      this.token_ecommerce = this.lazyUnit?.token_ecommerce ? this.lazyUnit.token_ecommerce : ''
    },
    setOfficeHours(start, finish) {
      this.lazyUnit.office_hours[this.selectedOfficeHoursDay][this.selectedOfficeHoursPosition] = {
        start,
        finish
      }
    },
    toggleEditOfficeHour(officeHoursDay, position, { start = null, finish = null } = {}) {
      this.settingOfficeHours = true
      this.selectedOfficeHoursDay = officeHoursDay
      this.selectedOfficeHoursPosition = position
      this.selectedOfficeHoursStart = start
      this.selectedOfficeHoursFinish = finish
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$error) {
        return this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: '#FF5252' })
      }
      const confirmed = await this.$confirm({
        message: 'Todas as alterações serão disponibilizadas no aplicativo imediatamente. Deseja realmente editar a unidade?',
        confirmColor: 'success',
        confirmText: 'Sim',
        cancelText: 'Voltar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmed) return
      this.saving = true
      try {
        this.lazyUnit.location = {
          type: 'Point',
          coordinates: [parseFloat(this.latitude.replace(',', '.')), parseFloat(this.longitude.replace(',', '.'))]
        }
        this.lazyUnit.address_components = cloneDeep(this.address)
        this.lazyUnit.address_components.postal_code = this.lazyUnit.address_components.postal_code.replace(/[^\d]+/g, '')
        this.lazyUnit.cnpj = this.lazyUnit.cnpj.replace(/[^\d]+/g, '')
        this.lazyUnit.address = this.internalFullAddress
        this.lazyUnit.mobile = this.lazyUnit.mobile || false
        this.lazyUnit.token_ecommerce = this.token_ecommerce
        this.lazyUnit.ecommerce_v2_active = this.lazyUnit.mercashop
        this.isFranchisee ? await this.saveCallback(this.lazyUnit, this.unit?._id, this.accountId) : await this.saveCallback(this.lazyUnit, this.unit?._id)
      } catch (e) {
        this.$snackbar({ message: 'Falha ao salvar alterações', snackbarColor: 'error' })
      }
      this.saving = false
      this.internalValue = false
      this.$emit('close')
    },
    generateToken() {
      this.token_ecommerce =
        Math.random()
          .toString(36)
          .substr(2) +
        Math.random()
          .toString(36)
          .substr(2)
    },
    cancel() {
      this.internalValue = false
      this.lazyUnit = {}
      this.$emit('close')
    },
    removeOfficeHoursInterval(officeHours, index) {}
  },
  validations: {
    latitude: {
      validNumber: latitude => latitude && !isNaN(latitude.replace(',', '.'))
    },
    longitude: {
      validNumber: longitude => longitude && !isNaN(longitude.replace(',', '.'))
    },
    lazyUnit: {
      corporate_name: { required },
      slug: { required },
      id_erp: { required, notOnlySpace },
      name: { required, notOnlySpace },
      cnpj: { required, validCnpj },
      contact: { required, notOnlySpace, minLength: minLength(18), maxLength: maxLength(19) },
      atLeastOneProductActive: value => {
        return Boolean(value?.cbm || value?.mercashop || value?.mercapromo || value?.promotrade)
      }
    },
    internalFullAddress: { required, notOnlySpace },
    address: {
      postal_code: { required, notOnlySpace, minLength: minLength(9), maxLength: maxLength(9) },
      federative_unit: { required, notOnlySpace },
      city_name: { required, notOnlySpace },
      neighborhood: { required, notOnlySpace },
      street: { required, notOnlySpace },
      number: { required, notOnlySpace, maxLength: maxLength(10) }
    }
  }
}
</script>

<style lang="scss">
.dialog-content {
  .block-title {
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }
  .detail-title {
    opacity: 0.8;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    color: #000000;
    margin-bottom: 8px;
  }

  .office-hours-title {
    padding: 0 0 0 12px;
    span {
      font-size: 1em;
      font-weight: bold;
    }
  }
  .closed-office-hours {
    text-decoration: line-through;
  }
  .office-hours-content-chips {
    .office-hours-chip {
      margin-bottom: 3px;
      padding-right: 0px;
      padding-left: 10px;
      span {
        padding-right: 5px;
      }
      .clickable {
        cursor: pointer;
      }
    }
  }
  .office-hours-content-new {
    padding-top: 5px;
    .office-hours-content-new-input {
      font-size: 0.75em;
      border: 1px solid #cecece;
      height: 20px;
      width: 50px;
      display: block;
    }
    i {
      padding-left: 5px;
    }
  }
}
</style>
